/*
 * @Author: duanyaqin
 * @Date: 2024-04-26 10:51:14
 * @Description:
 */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userId: '', // 当前登录用户id
    providerId: '', // 厂商id,
    role: '', // 角色值,
    providerName: '' // 厂商名称
  },
  mutations: {
    SET_USER_ID(state, data) {
      sessionStorage.setItem('userId', data);
      state.userId = data;
    },
    SET_PROVIDER_ID(state, data) {
      sessionStorage.setItem('providerId', data);
      state.providerId = data;
    },
    SET_ROLE(state, data) {
      sessionStorage.setItem('role', data);
      state.role = data;
    },
    SET_PROVIDER_NAME(state, data) {
      sessionStorage.setItem('providerName', data);
      state.providerName = data;
    }
  },
  actions: {
    setUserId({ commit }, data) {
      commit('SET_USER_ID', data);
    },
    setProviderId({ commit }, data) {
      commit('SET_PROVIDER_ID', data);
    },
    setProviderName({ commit }, data) {
      commit('SET_PROVIDER_NAME', data);
    },
    setRole({ commit }, flag) {
      commit('SET_ROLE', flag);
    }
  },
  getters: {
    userId: (state) => {
      state.userId = state.userId || sessionStorage.getItem('userId');
      return Number(state.userId);
    },
    role: (state) => {
      state.role = state.role || sessionStorage.getItem('role');
      return state.role;
    },
    providerId: (state) => {
      state.providerId = state.selectedPatientId || sessionStorage.getItem('providerId');
      return state.providerId;
    },
    providerName: (state) => {
      return state.providerName || sessionStorage.getItem('providerName');
    }
  }
});

export default store;
