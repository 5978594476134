import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import CusPatchMessage from '@/components/CusPatchMessage/CusPatchMessage';
import store from '@/store';
import errorCode from '@/utils/errorCode';
import { tansParams } from '@/utils';
import router from '../router';

// 是否显示重新登录
export const isRelogin = { show: false };

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    // 让每个请求携带自定义token 请根据实际情况自行修改
    config.headers['Authorization'] = sessionStorage.getItem('token')
      ? 'Bearer ' + sessionStorage.getItem('token')
      : '';
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }

    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.result.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.result.msg || errorCode['default'];
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res.data.result;
    }

    if (process.env.NODE_ENV === 'development' && code === 420) {
      CusPatchMessage.show({ message: msg, type: 'error', msgId: 'code500', duration: 3000 });
      return Promise.reject(new Error(msg));
    }

    if (code === 401) {
      if (!isRelogin.show) {
        isRelogin.show = true;
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            isRelogin.show = false;
            router.push({ path: '/login' });
            store.dispatch('louOut');
          })
          .catch(() => {
            isRelogin.show = false;
          });
      }
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。');
    } else if (code === 403) {
      CusPatchMessage.show({ message: msg, type: 'error', msgId: 'code403' });
      return Promise.reject(new Error(msg));
    } else if (code === 500) {
      CusPatchMessage.show({ message: msg, type: 'error', msgId: 'code500', duration: 3000 });
      return Promise.resolve( res.data.result);
    } else if (code === 601) {
      Message({ message: msg, type: 'warning' });
      return Promise.reject('error');
    } else if (code === 204) {
      CusPatchMessage.show({ message: msg, type: 'warning', msgId: 'code204' });
      return res.data.result;
    } else {
      return res.data.result;
    }
    // else if (code !== 200) {
    //   Notification.error({ title: msg })
    //   return Promise.reject('error')
    // }
  },
  (error) => {
    console.log('err' + error);
    const { message } = error || {};
    let erroMsg = '';
    if (message) {
      if (message == 'Network Error') {
        erroMsg = '后端接口连接异常';
      } else if (message.includes('timeout')) {
        erroMsg = '系统接口请求超时';
      } else if (message.includes('Request failed with status code')) {
        erroMsg = '系统接口' + message.substr(message.length - 3) + '异常';
      }
      Message({ message: erroMsg, type: 'error', duration: 5 * 1000 });
    }

    return Promise.reject(error);
  }
);

export function getAction(url, params = {}, timeout) {
  return service({
    url,
    method: 'get',
    params,
    timeout
  });
}

export function postAction(url, data, timeout) {
  return service({
    url,
    method: 'post',
    data,
    timeout
  });
}

export const http = {
  get: getAction,
  post: postAction
};

export default service;
