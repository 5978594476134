<!--
 * @Author: duanyaqin
 * @Date: 2024-04-26 09:26:05
 * @Description: 首页布局
-->
<template>
  <div>
    <el-container>
      <el-header>
        <div class="content-name">
          <img style="width: 60px; height: 60px; margin-right: 5px" src="../static/image/logo.png" />
          <div style="cursor: pointer" @click="openDialog">{{ showTitle() }}</div>
        </div>
        <el-menu
          :default-active="activeMenu"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          background-color="#0e3a65"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <el-menu-item index="/index">首页</el-menu-item>
          <el-menu-item index="/task">任务管理</el-menu-item>
          <el-menu-item index="/person">推广人员管理</el-menu-item>
          <el-menu-item index="/person/performance">修改手机号</el-menu-item>
          <el-menu-item index="/prove">完税证明</el-menu-item>
        </el-menu>
        <el-menu
          class="el-menu-demo menu-style"
          mode="horizontal"
          @select="handleLogout"
          background-color="#0e3a65"
          text-color="#fff"
          active-text-color="#fff"
        >
          <img style="width: 30px; height: 30px" src="../static/image/default.png" />
          <el-submenu index="6">
            <template slot="title">管理员</template>
            <el-menu-item>退出登录</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-header>
      <el-main><router-view></router-view></el-main>
    </el-container>
    <el-dialog
      title="切换厂商"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      custom-class="cus-dialog"
    >
      <el-radio-group v-model="titleProviderId">
        <el-radio v-for="(item, index) in list" :key="index" :label="item.id">{{ item.name }}</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      dialogVisible: false,
      radio: '1',
      list: [],
      titleProviderId: ''
    };
  },

  created() {
    this.handleSwitch();
  },
  watch: {
    $route: {
      handler(val) {
        this.activeIndex = val.path;
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['providerId', 'isAuthenticated']),

    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (path === '/index') {
        return '/index';
      }
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    }
  },
  methods: {
    ...mapActions(['setUserId', 'setProviderId', 'setProviderName', 'setRole']),
    showTitle() {
      const title = JSON.parse(sessionStorage.getItem('name'));
      return title || '';
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      if (this.$router.currentRoute.path !== key) {
        this.$router.replace(key);
      }
    },
    handleLogout() {
      this.$http
        .post('/yym/medicine/user/logout', {
          providerId: this.providerId
        })
        .then((res) => {
          console.log('logout success', res);

          if (res.code === 200) {
            sessionStorage.setItem('token', '');
            this.setUserId('');
            this.setProviderId('');
            this.setProviderName('');
            this.setRole('');
            this.$message.success('退出成功');
            this.$router.replace('/login');
          }
        });
    },
    handleConfirm() {
      this.dialogVisible = false;
      if (this.$route.path !== '/index') {
        this.$router.push('/index');
      }
      this.$bus.$emit('refreshProvider');
      const { titleProviderId, list } = this;
      const item = list.find((item) => item.id == titleProviderId);
      if (item) {
        this.setProviderId(item.id);
        sessionStorage.setItem('providerId', item.id);
        sessionStorage.setItem('name', JSON.stringify(item.name));
      }
    },
    openDialog() {
      this.dialogVisible = true;
      // this.handleSwitch();
    },
    handleSwitch() {
      this.$http
        .post('/yym/medicine/user/providers', {
          providerId: this.providerId
        })
        .then((res) => {
          if (res.code == 200) {
            const { data } = res;
            this.list = data;
            if (data && data.length > 0) {
              this.titleProviderId = data[0].id;
              this.setProviderId(data[0].id);
              sessionStorage.setItem('providerId', data[0].id);
              sessionStorage.setItem('name', JSON.stringify(data[0].name));
            }
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .cus-dialog .el-dialog__body {
  overflow-y: visible !important;
  text-align: left;
}
.el-header {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  color: #fff;
  text-align: center;
  background: #0e3a65;
}

.el-main {
  background-color: #f5f6fa;
  text-align: center;
  height: calc(100vh - 60px);
}

body {
  margin: 0;
}

.content-name {
  display: flex;
  flex-direction: row;
  height: 60px;
  line-height: 60px;
  margin: 0 132px;
  font-size: 20px;
  font-weight: 700;
}

.menu-style {
  display: flex;
  flex-direction: row;
  margin-left: 132px;
  justify-content: center;
  align-items: center;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.el-radio {
  width: 100%;
  text-align: left;
  padding: 10px 0;
}
</style>
