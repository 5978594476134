/*
 * @Author: duanyaqin
 * @Date: 2024-04-26 10:52:06
 * @Description: main.js
 */
import Vue from 'vue';
import App from './App.vue';
import VueClipboard from 'vue-clipboard2';
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import store from './store';
import { http } from '@/utils/request';
import '@/assets/styles/custom.scss';

Vue.use(VueRouter);
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);
Vue.use(ElementUI);
Vue.config.productionTip = false;

// 全局属性挂载
Vue.prototype.PAGE_SIZE = 10;
Vue.prototype.$http = http;

new Vue({
  router,
  store,
  beforeCreate () {
    Vue.prototype.$bus = this;
  },
  render: (h) => h(App)
}).$mount('#app');
