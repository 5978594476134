/*
 * @Author: duanyaqin
 * @Date: 2024-04-26 09:52:04
 * @Description: 路由配置
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login')
  },
  {
    path: '',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/home'),
        name: 'index',
        meta: { title: '首页' }
      },
      {
        path: 'task',
        component: () => import('@/views/task'),
        name: 'task',
        meta: { title: '任务管理' }
      },
      {
        path: 'task/imageCheck',
        component: () => import('@/views/task/imageCheck'),
        name: 'imageCheck',
        meta: { title: '图片审核', activeMenu: '/task' }
      },
      {
        path: 'person',
        component: () => import('@/views/personnel'),
        name: 'person',
        meta: { title: '推广人员管理' }
      },
      {
        path: 'person/performance',
        component: () => import('@/views/personnel/performance'),
        name: 'performance',
        meta: { title: '绩效' }
      },
      {
        path: 'personnel/personnelModal',
        component: () => import('@/views/personnel/personnelModal'),
        name: 'personnelModal',
        meta: { title: '查看绩效', activeMenu: '/person' }
      },
      {
        path: 'prove',
        component: () => import('@/views/prove'),
        name: 'prove',
        meta: { title: '完税证明' }
      }
    ]
  },
  {
    path: '/*',
    component: () => import('@/views/error/404'),
    hidden: true
  }
];

const router = new VueRouter({
  routes
});

// 前置路由守卫
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '药企管理端';
  if (to.path == from.path) return;

  if (to.path !== '/login' && !isUserLoggedIn()) {
    next('/login'); // 重定向到登录页面
  } else {
    next(); // 继续路由跳转
  }
});

function isUserLoggedIn() {
  // 这里实现身份验证逻辑，例如检查token或其他方式
  return !!sessionStorage.getItem('token');
}

export default router;
