/**
 * @Description: 重写message挂载，实现 Class 的私有属性
 * @param { String } options => 消息内容
 * @author zhaoyu
 */
import ELEMENT from 'element-ui';
let previousId = '';
class CusPatchMessage {
  show(options) {
    if (previousId === options.msgId) return;
    ELEMENT.Message[options.type](options);
    previousId = options.msgId;
    setTimeout(() => {
      previousId = '';
    }, options.duration || 3000);
  }
}

// 默认导出 私有 Message 组件
export default new CusPatchMessage();
